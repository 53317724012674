<div class="admin-bar" *ngIf="isAdmin">
  <app-admin-bar class="admin-header-bar" [viewType]="'organization'"></app-admin-bar>
</div>

<div class="d-flex flex-row flex-column flex-lg-row align-items-start justify-content-between gap-2 config-page-header"
     *ngIf="!!organization">
  <div class="d-flex flex-column w-100 title-and-org">
<!--PHASE 2    <img class="org-img" src="assets/icons/LU.jpg" alt="">-->
    <div class="d-flex flex-column gap-3 heading">
      <div class="x-large-x-bold-text">{{organization.name}}</div>
      <button type="button" class="custom-button-small-secondary small-medium-text gap-2"
              [routerLink]="['/', pageType, 'organization', 'details', organization.id]">View Organization Page</button>
     </div>
  </div>

    @if((isLeadSupervisor$ | async)) {
        <div class="d-flex flex-column flex-lg-row gap-2 org-actions">
            <button type="button" class="custom-button-large-secondary small-medium-text gap-2"
                    [routerLink]="['/', pageType, 'opportunity', 'request', organization.id]">
                <app-icon icon="plus" class="small-icon"></app-icon>
                <span>New Opportunity</span>
            </button>
            <button type="button" class="custom-button-large-secondary small-medium-text gap-2"
                    (click)="openNewSupervisorModal()">
                <app-icon icon="user-plus" class="small-icon"></app-icon>
                <span>New Supervisor</span>
            </button>
        </div>
    }
</div>

<div class="px-3 px-lg-5 tab-container">
  <div role="tablist" class="tabs" aria-labelledby="tablist">
    <a role="tab" class="tab-state small-medium-text" [ngClass]="isActive('opportunities')"
       aria-label="Opportunities" [routerLink]="['opportunities']">Opportunities</a>

    <a role="tab" class="tab-state small-medium-text" [ngClass]="isActive('supervisors')"
       aria-label="Supervisors" [routerLink]="['supervisors']" >Supervisors</a>
  </div>
</div>
<router-outlet></router-outlet>
